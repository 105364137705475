import { render, staticRenderFns } from "./MediaListWidget.vue?vue&type=template&id=495ef9b0&scoped=true"
import script from "./MediaListWidget.vue?vue&type=script&lang=js"
export * from "./MediaListWidget.vue?vue&type=script&lang=js"
import style0 from "./MediaListWidget.vue?vue&type=style&index=0&id=495ef9b0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "495ef9b0",
  null
  
)

export default component.exports